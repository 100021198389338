import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormService } from 'src/app/core/form.service';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.scss']
})
export class FileDialogComponent implements OnInit {

  constructor(public form: FormService,
    private dialogRef: MatDialogRef<FileDialogComponent>) { }

  ngOnInit(): void {
  }
  public error;
  public buttonFileText = "Browse File"
  public retVal = undefined;
  public btnUpdate = false;

  onFileChange(ev) {
  
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.buttonFileText = "Uploading in progress..."
    const maxFileSize = 400 * 1024; //400kb limit

    if (file.type == "application/vnd.ms-excel" || file.type == "text/csv") {
      this.error = ''
      reader.onload = (event) => {
        console.log(new Date());

        let data = String(reader.result)

        let eids = '';
        data.split(/\r?\n/).forEach(eid => { if (eid != '' && eid != undefined) eids = eids == '' ? eid.replace(/[^ -~]+/g, "") : eids += "; " + eid.trim().replace(/[^ -~]+/g, "") })

        if(file.size > maxFileSize){
          this.error = 'File size exceeded 400kb limit. File should be not exceed 400Kb.'
        }
        else if (this.form.data.transaction.ragStatus == 'Emerging Threat'){
          let csvresult = [];
          let formattedCDPTracker = '';
          let accepted = [];
          let unaccepted = [];
          let finalEidsandCdptracker = ''

          eids.split(';').forEach(eid =>{
            csvresult.push(eid.trim().split(','))
          })

          accepted = csvresult.filter(csvitem => csvitem.length == 2 && csvitem[0] != "" && csvitem[1] != "")
          unaccepted = csvresult.filter(csvitem => csvitem.length != 2)
  
          
          for (let x = 0; x<accepted.length; x++){

            if (x != accepted.length - 1){
              finalEidsandCdptracker +=  accepted[x][0] + ',' + accepted[x][1] + ';';
            }
            else{
              finalEidsandCdptracker +=  accepted[x][0] + ',' + accepted[x][1];
            }
          }
          
          if (accepted.length==0){
            this.error = "Unexpected file format. Please upload a .csv file with two columns: 1. EID, 2. CDP tracker ID"
          } else {
            this.buttonFileText = file.name;
            this.btnUpdate = true;
            this.retVal = {
              status: 'done',
              fileName: file.name,
              eids: finalEidsandCdptracker,
              cdpTracker: formattedCDPTracker,
              accepted: accepted,
              unaccepted: unaccepted,
            }
          }
          
        } else {
          if (eids == '') {
            this.error = 'No EIDs found .'
          } else {
            this.buttonFileText = file.name;
            this.btnUpdate = true;
            this.retVal = {
              status: 'done',
              fileName: file.name,
              eids: eids,
            }
          }
        }
      }
      reader.readAsBinaryString(file);
    } else {
      this.error = 'Only csv file is allowed for upload. Try again.'
      this.buttonFileText = "Browse File"
      this.btnUpdate = false;
    }
  }

  close() {
    this.retVal = {
      status: 'close',
      fileName: '',
      eids: ''
    }

    this.dialogRef.close(this.retVal)
  }

  upload(){
    if(this.retVal){

    this.dialogRef.close(this.retVal)
  }

  }
}
