import { EsoService } from './core/eso.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var  startDatadogScript, DDrumSdkIni;
import { BnNgIdleService } from 'bn-ng-idle';//for session timeout
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';  

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public eso: EsoService,
    private bnIdle: BnNgIdleService,//for session timeout
    public router: Router,
    private msalService: MsalService
    ){
      this.eso.configure();
  }

  title = 'ASA';
  ngOnInit(){
    //for session timeout
    this.bnIdle.startWatching(600).subscribe((isTimedOut: boolean) => {
      //600 seconds for 10 minutes
      if (isTimedOut) {
        console.log('>>>>>>session expired');
        //this.router.navigateByUrl('/unauthorized');
        sessionStorage.removeItem('access_token');
        this.msalService.logoutRedirect();
      }
    });//end for session timeout 
  try{
    // Dynamic creation of script tag with src of DataDog Rum SDK
    const scriptElem = document.createElement('script');
    scriptElem.type = 'text/javascript';
    scriptElem.src = environment.dataDog.scriptSrc;

    scriptElem.addEventListener('load',()=>{
      console.log('script is loaded');
      // calls the script that will record session to datadog
      startDatadogScript(environment.dataDog);
    });

    document.head.appendChild(scriptElem);
  }
  catch(e){
    console.log('datadogScriptError',e)
  }

  }
}
