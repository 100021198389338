import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EsoService {
  public decode: BASE64 = new BASE64();
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  authenticated = false;
  constructor(
    private oauthService: OAuthService,
    private oauthStorage: OAuthStorage,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) { }

  public configure() {
    console.log('configure')
      try{
        this.isIframe = window !== window.parent && !window.opener;

        this.setLoginDisplay();

        this.msalBroadcastService.msalSubject$
          .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
            takeUntil(this._destroying$)
          )
          .subscribe((result) => {
            console.log('success',result);
            this.setStorage(result.payload)
            this.authenticated = true;
            this.setLoginDisplay();
          });

          this.msalBroadcastService.inProgress$
          .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)
          ,takeUntil(this._destroying$))
          .subscribe(async () => {
            if (!this.authenticated) {
              await this.loginRedirect();
            }
          });

      }catch(error){
        console.error(error);
      }
  }


  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  private setStorage(result){
    console.log(result);

    if(result){
      console.log('set value')

      this.oauthStorage.setItem('access_token', result.accessToken);
      this.oauthStorage.setItem('token_type', result.tokenType);
      this.oauthStorage.setItem('expires_in', result.expiresOn);
      this.oauthStorage.setItem('id_token', result.idToken);
      this.oauthStorage.setItem('state', result.state);
      sessionStorage.setItem('ddrumsdk.idtoken', result.idToken); //DatadogRUM
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  /**
   *  getToken
   */
  public getToken() {
    return new Observable(observer => {
      try {
        let value = this.oauthStorage.getItem('access_token');

        if (value != null) {
          observer.next(value);
        } else {
          if(environment.isTest){
            observer.next('no identity');
          }else{
            observer.error('No token saved')
          }
        }
      } catch (error) {
        observer.error(error);
      }

    })
  }

  public getIdToken() {
    return new Observable(observer => {
      try {
        let value = this.oauthStorage.getItem('id_token');

        if (value != null) {
          observer.next(value);
        } else {
          if(environment.isTest){
            observer.next('no identity');
          }else{
            observer.error('No token saved')
          }
        }
      } catch (error) {
        observer.error(error);
      }

    })
  }

  public getIdentity() {
    return new Observable(observer => {
      try {
        let oauthData = this.oauthService;
        let value = this.oauthService.getIdentityClaims();


        if (value != null) {
          observer.next(value);
        } else {
          console.log("value identity", value)
          observer.error('No identity claims');
        }
      } catch (error) {
        observer.error(error);
      }
    })
  }
}



export class BASE64 {
  constructor() { }
  private _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  decode(input: string): string {
    var output = "";
    var enc1, enc2, enc3, enc4: number;
    var i = 0;

    input = input.split(".")[1];
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

    while (i < input.length) {

      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      var chr1 = (enc1 << 2) | (enc2 >> 4);
      var chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      var chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }

    }

    output = this._utf8_decode(output);
    return output;
  }

  private _utf8_decode(utftext: string): string {
    var output = "";
    var i = 0;
    var c, c2, c3: number;
    c = 0;
    c2 = 0;
    c3 = 0;

    while (i < utftext.length) {

      c = utftext.charCodeAt(i);

      if (c < 128) {
        output += String.fromCharCode(c);
        i++;
      }
      else if ((c > 191) && (c < 224)) {
        c2 = utftext.charCodeAt(i + 1);
        output += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      }
      else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        output += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }

    }
    return output;
  }
}
