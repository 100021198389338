import { Injectable } from '@angular/core';
import { AhttpService } from './ahttp.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: AhttpService
  ) { }

  getCountries() {
    console.log('getCountries')
    let url = environment.api.getCountries;
    return this.http.get(url);
  }

  getTransaction(filter, start, limit, lastEvaluated){
    console.log('get transaction');
    let url = environment.api.transaction+"?start="+start+"&take="+limit+"&lastEvaluated="+lastEvaluated+"&"+filter;

    return this.http.get(url);
  }

  getTransactionDeliveryDetail(alertID){
     console.log('get detail');
     let url = environment.api.transaction+ '/' + alertID + '?deliverydetail=True';
     return this.http.get(url);
  }

  getQALimit(alertID, refQID, keyAnswer){
    console.log('get QALimit by refQID and keyAnswer');
    let url = environment.api.transaction+ '/' + alertID + '?getQALimit=True&qid=' + encodeURI(refQID) + '&keyAnswer=' + keyAnswer;
    console.log("TEST QALimit URL: " + url);
    return this.http.get(url);
  }

  getTransactionAllMobileUsers(startItem){
    console.log('get all mobile users');
    let url = environment.api.transaction+ '/0?getAllMobileUsers=True&startItem=' + startItem;
    return this.http.get(url);
  }

  getTransactionRecipient(alertID,startItem){
    console.log('get total recipients');
    let url = environment.api.transaction+ '/' + alertID + '?getRecipientsByAlertId=True&startItem=' + startItem;
    return this.http.get(url);
 }
//   getSMSTransactionRecipient(alertID,startItem){
//     console.log('get total recipients');
//     let url = environment.api.transaction+ '/' + alertID + '?getSMSRecipientsByAlertId=True&startItem=' + startItem;
//     return this.http.get(url);
//  }

 getRecipientByID(alertID){
  console.log('get total recipients');
  let url = environment.api.transaction+ '/' + alertID + '?selectedCell=True';
  return this.http.get(url);
}

  postTransaction(param) {
    console.log('post transaction');
    let url = environment.api.transaction;

    return this.http.post(url, param);
  }

  postCheckRecipients(eid, param){
    console.log('post checkrecipient');
    let url = environment.api.registration+'/'+eid+'?compare=True';
    return this.http.post(url, param);
  }

  // postCheckSMSRecipients(eids, eid){
  //   console.log('post checkSMSrecipient');
  //   let url = environment.api.registration+'/'+eid+'?smishing=True';
  //   return this.http.post(url, eids);
  // }

  postCheckCDPUsers(param){
    let url = environment.api.cdpUsers;
    return this.http.post(url, param);
  }

  postCheckCDPTracker(eid,param){
    let url = environment.api.score + '/' + eid;
    return this.http.post(url, param);
  }

  putTransaction(alertID, param) {
    console.log('put transaction');
    let url = environment.api.transaction + '/' + alertID;

    return this.http.put(url, param);
  }

  postNotification(param) {
    console.log('post notification');
    let url = environment.api.notification;

    return this.http.post(url, param);
  }

  postNotificationAD(param) {
    console.log('post notification AD');
    let url = environment.api.notificationAD;

    return this.http.post(url, param);
  }

  postNotificationAllMobileUsers(param) {
    console.log('post notification All Mobile Users');
    let url = environment.api.notificationAllMobileUsers;

    return this.http.post(url, param);
  }

  postNotificationMultipleUsers(param) {
    console.log('post notification Multiple Users');
    let url = environment.api.notificationMultipleUsers;

    return this.http.post(url, param);
  }

  // postNotificationSMS(param){
  //   console.log('post notification SMS');
  //   let url = environment.api.notificationSMS;

  //   return this.http.post(url, param);
  // }

  getQADetails(alertId) {
    console.log('post alert response')
    let url = environment.api.getQADetails + "/" + alertId
    return this.http.get(url)
  }
  updateNotification(param, body) {
    console.log('get count', param)
    let qs = "/" + param.eid + "-" + param.alertID + "-" + param.type;
    let url = environment.api.alert + qs;
    return this.http.put(url, body);
  }

  getReportDetails(alertId){
    console.log('get report')
    let url = environment.api.getReport + "/" + alertId
    return this.http.get(url)
  }

  deleteTransaction(alertId){
    console.log('deleteID: ' + alertId)
    let url = environment.api.transaction + "/" + alertId
    return this.http.delete(url)
  }

  updateQADetails(Id, param){
    let url = environment.api.getQADetails + "/" + Id
    return this.http.put(url, param)
  }

  checkBatchStatus(){
    let url = environment.api.transaction + "/0?checkBatchStatus=True"
    return this.http.get(url);
  }

  sendReminder(param){
    let url = environment.api.reminder;
    return this.http.post(url, param)
  }

  getNotification(eid){
    let qs = "?eid="+eid+"&count=False"
    let url = environment.api.alert + qs;
    return this.http.get(url);
  }

  getAlertResponseCDPDetails(alertId, eid){
    console.log('get alert response CDP Details')
    let url = environment.api.getAlertResponse + "/" + alertId + "?getCDPDetails=True&eid=" + eid;
    return this.http.get(url)
  }

  getAlertResponse(alertId){
    console.log('get alert response')
    let url = environment.api.getAlertResponse + "/" + alertId;
    return this.http.get(url)
  }

  getAccessMapping(eid){
    let url = environment.api.accessmapping + "/" + eid
    return this.http.get(url)
  }

  getAccessReports(eid){
    let url = environment.api.accessreports + "/" + eid
    return this.http.get(url)
  }

  getAnswer(qid){
    console.log("get answer")
    let url = environment.api.Answer + "/" + qid
    // url = encodeURIComponent(url)
    url = encodeURI(url)
    return this.http.get(url)
  }

  updateQAStatus(param, body){
    console.log('get count')
    let qs = "/"+param.Id+"-"+param.eid+"/qastatus?qastatus=True";
    let url = environment.api.alert + qs;
    return this.http.put(url, body);
  }

  getAllAnswered(qid){
    console.log("get all answered questions")
    let url = environment.api.Answer + "/" + qid + "?hasAnswered=True"
    // url = encodeURIComponent(url)
    url = encodeURI(url)
    return this.http.get(url)
  }

  postAnswer(param) {
    console.log('post answer', param)
    let url = environment.api.getAlertResponse;
    return this.http.post(url, param);
  }

  getReportsPage(){
    let url = environment.api.getReportsPage;
    return this.http.get(url);
  }

  generateReport(table, LastEvaluatedKey, row, col){
    let url = environment.api.reports + "/" + table + "?row=" + row + "&col=" + col;
    let param = LastEvaluatedKey
    console.log("get:c", url)

    return this.http.post(url, param)
  }

  getCDPOperationDataTest(alertID, eid){
    console.log("cdp eid")
    let url = environment.api.score + "/" + eid + "?testOperationData=true&alertID=" + alertID;
    return this.http.get(url)
  }

  updateETstatus(param,body){
    console.log('get count', param)
    let qs = "/" + param.eid + "-" + param.alertID + "-" + param.type;
    let url = environment.api.alert + qs + "?etStatus=True";
    return this.http.put(url, body);
  }

  getTotalReads(alertID){
    let url = environment.api.totalReads + "/" + alertID;
    return this.http.get(url);
  }

  getTotalPending(alertID){
    let url = environment.api.totalReads + "/" + alertID + "?pending=True";
    return this.http.get(url);
  }

  postETstatus(body){
    let url = environment.api.etstatus;
    console.log("post ", url)
    return this.http.post(url, body)
  }


  // getTotalPendingSMS(alertID){
  //   let url = environment.api.totalReads + "/" + alertID + "?pendingsms=True";
  //   return this.http.get(url);
  // }

  // postPhoneNumbers(param){
  //   let url = environment.api.phoneNumbers;
  //   return this.http.post(url, param);
  // }
}
