import { transition } from '@angular/animations';
import { TransactionComponent } from './../admin/transaction/transaction.component';
import { Injectable, OnInit } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { HandlerService } from './handler.service';
import { ApiService } from './api.service';
import { count } from 'rxjs/operators';
import { UserService } from './user.service';
import { ExcelService } from './excel.service';
import { DownloadService } from './download.service';
import { ObserversModule } from '@angular/cdk/observers';
// import { PhoneService } from "./phone.service"

@Injectable({
  providedIn: 'root'
})
export class FormService implements OnInit {

  public serviceData: any = {};
  public config =
    {
      alerts: [
        {
          type: "EmergingThreat",
          description: "Emerging Threat"
        },
        {
          type: "CriticalCyberNotifications",
          description: "Critical Cyber Notifications"
        },
        {
          type: "AccentureCyberNews",
          description: "Accenture Cyber News"
        },
        {
          type: "CyberNewsAroundtheWorld",
          description: "Cyber News Around the World"
        },
        // {
        //   type: "SmishingTest",
        //   description: "SMISHING Test (SMS)"
        // },
      ],
      categories: [
        {
          type: "Custom-Role",
          description: "Custom - Role"
        },
        {
          type: "Custom-Accenture",
          description: "Custom - Accenture"
        },
        {
          type: "Custom-NonAccenture",
          description: "Custom - Non Accenture"
        },
        {
          type: "General-Role",
          description: "General - Role"
        },

        {
          type: "Genera-Accenture",
          description: "General - Accenture"
        },
        {
          type: "General-NonAccenture",
          description: "General - Non Accenture"
        },

      ],

      // this options should be in mobile onlye
      recipients: {
        standard: [
          {
            type: "ALL",
            description: "All Mobile Users"
          },
          {
            type: "eids",
            description: "Individual Names (Enter ID)"
          },
          {
            type: "file",
            description: "Individual Names (Upload Excel)"
          }
        ],
        emergingThreat: [
          {
            type: "eids",
            description: "Individual Names (Enter ID)"
          },
          {
            type: "file",
            description: "Individual Names (Upload Excel)"
          }
        ]

      }
    };

  constructor(
    public handler: HandlerService,
    public webApi: ApiService,
    public user: UserService,
    public excel: ExcelService,
    public download: DownloadService,
    // public phone: PhoneService
  ) {
    // console.log("billy start")
    if (!this.serviceData.isLoggedIn || this.serviceData.isLoggedIn == undefined) {
      this.setUser();
    }
  }

  ngOnInit() {
  }


  public setUser() {
    this.user.loadUser().subscribe((user: any) => {
      console.log('loading user');
      this.serviceData.isLoggedIn = true;
      this.serviceData.user = user;
    })
  }
  /**
   * gets the service data save in it.
   */
  public get data(): any {
    if (this.serviceData == null || this.serviceData == undefined) {
      throw "Service data variable in form service is null or undefined.";
    }
    return this.serviceData;
  }


  /**
   * saveTransaction
   */
  public saveTransaction(tobeSave) {
    return new Observable(observer => {
      this.webApi.postTransaction(tobeSave).subscribe(data => {
        console.log('return from web api ', data);
        if (data.statusCode == 201 || data.statusCode == 200) {
          observer.next(data.body)
        }
      }, error => {
        observer.error(error)
      })
    });
  }


  /**
   * updateTransaction
   */
  public updateTransaction(alertID, tobeSave) {
    return new Observable(observer => {
      this.webApi.putTransaction(alertID, tobeSave).subscribe(data => {
        console.log('return from web api ', data);
        if (data) {
          observer.next(data.body)
        }
      }, error => {
        observer.error(error)
      })
    });
  }


  /*
  * updates notificaiotn alert.
  */
  public markAlert(param) {
    return new Observable(observer => {
      let body = {
        "userNotifId": "",
        "userNotifIds": param.userNotifIds,
        "updateObject": { "nStatus": param.status }
      }

      this.webApi.updateNotification(param.param, body).subscribe((data: any) => {
        observer.next(data);
      }, error => {
        console.error(error);
        observer.error(error);
      })

    });
  }

  public sendNotification(tobeSend, isAD, isAllMobileUsers, isMultipleUsers) {
    console.log("~~ public send notitifcation", tobeSend, isAD, isAllMobileUsers, isMultipleUsers)
    return new Observable(observer => {
      if(isAD){
        this.webApi.postNotificationAD(tobeSend).subscribe(data => {
          console.log('return from web api ', data);
          observer.next(data)
        }, error => {
          observer.error(error)
        })
      }else if(isAllMobileUsers){
        this.webApi.postNotificationAllMobileUsers(tobeSend).subscribe(data => {
          console.log('return from web api ', data);
          observer.next(data)
        }, error => {
          observer.error(error)
        })
      }else if(isMultipleUsers){
        this.webApi.postNotificationMultipleUsers(tobeSend).subscribe(data => {
          console.log('return from web api ', data);
          observer.next(data)
        }, error => {
          observer.error(error)
        })
      }
      // else if (isSMS){
      //   this.webApi.postNotificationSMS(tobeSend).subscribe(data => {
      //     console.log('return from web api ', data);
      //     observer.next(data)
      //   }, error => {
      //     observer.error(error)
      //   })
      // }
      else{
        this.webApi.postNotification(tobeSend).subscribe(data => {
          console.log('return from web api ', data);
          observer.next(data)
        }, error => {
          observer.error(error)
        })
      }
    });
  }

  // public publishNotification(tobeSend, isAD) {
  //   return new Observable(observer => {
  //     if(isAD){
  //       this.webApi.postNotification(tobeSend).subscribe(data => {
  //         console.log('return from web api ', data);
  //         if (data.statusCode == 201) {
  //           observer.next(data.body)
  //         }
  //       }, error => {
  //         observer.error(error)
  //       })
  //     }else{
  //       this.webApi.postNotificationAD(tobeSend).subscribe(data => {
  //         console.log('return from web api ', data);
  //         if (data.statusCode == 201) {
  //           observer.next(data.body)
  //         }
  //       }, error => {
  //         observer.error(error)
  //       })
  //     }

  //   });
  // }

  /*
  */
  public getTransacation(filter, start = 0, limit = 1000) {
    return new Observable(observer => {
      let filterValue = "";
      for (let prop in filter) {
        filterValue = filterValue == '' ? filterValue += prop + "=" + filter[prop] : filterValue += "&" + prop + "=" + filter[prop]
      }
      console.log("filtervalue", filterValue)
      let returnVal = {
        list: []
      }
      let lastEvaluated = "start"
      let self = this.webApi
      let serviceData = this.serviceData

      onscan()
      function onscan(){
        self.getTransaction(filterValue, start, limit, lastEvaluated).subscribe(data => {
          start = start + 5
          console.log('return from web api ' , data);
          if (data.statusCode == 200) {
            serviceData.transaction = serviceData.transaction ? serviceData.transaction : {};
            serviceData.transaction.toBeDeleted = {
              Publish: [],
              Pending: []
            }
            returnVal.list.push(...data.body.list)
            console.log("returnvalll ", returnVal)
            console.log("lastitem " , data.body.lastEvaluatedKey)
            console.log("start ", start)
             if(data.body.lastEvaluatedKey != undefined){
              lastEvaluated = data.body.lastEvaluatedKey.alertID
              onscan()
             } else {
              observer.next(returnVal)
             }
          }
        }, error => {
          observer.error(error)
        })
      }

    });
  }

  /**
   * checkRecipients
   */
  public checkRecipients(eid, param) {
    return new Observable(observer => {
      this.webApi.postCheckRecipients(eid, param).subscribe((data: any) => {
        if (data.statusCode == 201) {
          let tempArray = []
          if(data.body){
            data.body.forEach(element => {
              tempArray.push(element + "@accenture.com")
            });
            this.serviceData.temporary = tempArray
          }
          observer.next(true);
        } else {
          observer.next(false);
        }
      }, error => observer.error(error))
    })
  }
  /**
   * checkSMSRecipients
   */
  // public checkSMSRecipients(eids, eid) : Observable <any>  {
  //   return new Observable(observer => {
  //     let eidsmishinglist = eids.split(';')
  //     let tobeSend = {
  //         eids:eidsmishinglist
  //     }
  //     this.webApi.postCheckSMSRecipients(tobeSend, eid).subscribe((data: any) => {
  //       if (data.statusCode == 201) {
  //         observer.next(data.body)
  //       } else {
  //         observer.next(false);
  //       }
  //     }, error => observer.error(error))
  //   })
  // }


  /**
   * checkCDPUsers
   */
   public checkCDPUsers(param) {
    return new Observable(observer => {
      this.webApi.postCheckCDPUsers(param).subscribe((data: any) => {
        let statuscode = data.statusCode == undefined ? data["body-json"].statusCode : data.statusCode;
        if (statuscode == 201) {
          // this.serviceData.temporary = this.serviceData.temporary ? this.serviceData.temporary : {};

          // this.serviceData.temporary = data.body == undefined ? data["body-json"].body : data.body;
          let tempArray = this.serviceData.temporary
          if(data.body){
            data.body.forEach(element => {
              tempArray.push(element + "@accenture.com")
            });
            this.serviceData.temporary = tempArray
          }
          statuscode = null
          observer.next(true);
        } else {
          observer.next(false);
        }
      }, error => observer.error(error))
    })
  }

  /**
   * checkCDPTracker
   */
    public checkCDPTracker(eid,param) {
      return new Observable(observer => {
        this.webApi.postCheckCDPTracker(eid,param).subscribe((data: any) => {
          let statuscode = data.statusCode == undefined ? data["body-json"].statusCode : data.statusCode;
          if (statuscode == 201) {
            this.serviceData.temporary = []
            let tempArray = []             
            if(data.body){
              data.body.forEach(element => {
                tempArray.push(element)
              });
              this.serviceData.temporary = tempArray
            }

            statuscode = null
            observer.next(data.body);
          } else {
            observer.next(null);
          }
        }
        , error => observer.error(error))
      })
    }

  /**
   *
   * @param alertID Transaction alert ID
   * get delivery detail of an transaction
   */
  public getTransactionDeliveryDetail(alertID) {
    return new Observable(observer => {
      this.webApi.getTransactionDeliveryDetail(alertID).subscribe((data: any) => {
        console.log('return from web api ', data);
        if (data.statusCode == 200) {
          // if(this.serviceData.transaction.alertType.type == "SmishingTest"){
          //   this.webApi.getTotalPendingSMS(alertID).subscribe((totalpendingsms: any)=>{
          //     data.body.totalPendingSMS = totalpendingsms.body.length;
          //   this.serviceData.transaction.deliveryDetail = [];
          //    if (this.serviceData.Report) {
          //     data.body.totalRead = this.serviceData.Report.report[0].dataRead
          //   }
          //   this.serviceData.transaction.deliveryDetail.push(data.body);
          //   observer.next(data.body);
          // })
          // }else {
            this.webApi.getTotalPending(alertID).subscribe((totalpending: any)=>{
              data.body.totalPending = totalpending.body.length;
            this.serviceData.transaction.deliveryDetail = [];
             if (this.serviceData.Report) {
              data.body.totalRead = this.serviceData.Report.report[0].dataRead
            }
            this.serviceData.transaction.deliveryDetail.push(data.body);
            observer.next(data.body);
          })

        }
      }, error => observer.error(error))
    })
  }

  public getQALimit(alertID, refQID, keyAnswer){
    return new Observable(observer => {
      this.webApi.getQALimit(alertID, refQID, keyAnswer).subscribe((data: any) => {
        console.log('return from web api ', data);
        if (data.statusCode == 200) {
          this.serviceData.transaction.QALimit = [];
          this.serviceData.transaction.QALimit.push(data.body);
          observer.next(data.body);
        }
      })
    })
  }

  /**
   *
   * Transaction All Mobile Users
   * get all mobile users for transaction
   */
  public getTransactionAllMobileUsers(startItem) {
    return new Observable(observer => {
      this.webApi.getTransactionAllMobileUsers(startItem).subscribe((data: any) => {
        console.log('return from web api ', data);
        if (data.statusCode == 200) {
          this.serviceData.transaction.allMobileUsers = [];
          this.serviceData.transaction.allMobileUsers.push(data.body);
          observer.next(data.body);
        }
      }, error => observer.error(error))
    })
  }

  /**
   *
   * @param alertID Transaction alert ID
   * get total recipients of a transaction
   */
  public getTransactionRecipient(alertID,startItem) {
    return new Observable(observer => {
      // if(this.serviceData.transaction.alertType.type == "SmishingTest"){
      //    this.webApi.getSMSTransactionRecipient(alertID,startItem).subscribe((data: any) => {
      //   console.log('return from web api ', data);
      //   if (data.statusCode == 200) {
      //     this.serviceData.transaction.totalRecipients = [];
      //     this.serviceData.transaction.allRecipients = [];
      //     this.serviceData.transaction.totalRecipients.push(data.body);
      //     console.log("checking ", this.serviceData.transaction.totalRecipients[0].users.length)
      //     console.log("HELLOOOO", data.body.users)
      //     console.log("this.serviceData.transaction.allRecipients", this.serviceData.transaction.allRecipients)
      //     if(this.serviceData.transaction.totalRecipients[0].users.length > 0 ){
      //       this.serviceData.transaction.totalRecipients[0].users.forEach(element => {
      //         this.serviceData.transaction.allRecipients.push(element.split('|')[0] + "@accenture.com")
      //       });
      //     }
      //     observer.next(data.body);
      //   }
      // }, error => observer.error(error))
      // }else{
        this.webApi.getTransactionRecipient(alertID,startItem).subscribe((data: any) => {
          console.log('return from web api ', data);
          if (data.statusCode == 200) {
            this.serviceData.transaction.totalRecipients = [];
            this.serviceData.transaction.allRecipients = [];
            this.serviceData.transaction.totalRecipients.push(data.body);
            console.log("checking ", this.serviceData.transaction.totalRecipients[0].users.length)
            if(this.serviceData.transaction.totalRecipients[0].users.length > 0 ){
              this.serviceData.transaction.totalRecipients[0].users.forEach(element => {
                this.serviceData.transaction.allRecipients.push(element.split('|')[0] + "@accenture.com")
              });
            }
            observer.next(data.body);
          }
        }, error => observer.error(error))
      }

    )
  }

  /**
   *
   * @param alertID Transaction alert ID
   * get recipients by ID of a transaction
   */
   public getRecipientById(alertID) {
    return new Observable(observer => {
      this.webApi.getRecipientByID(alertID).subscribe((data: any) => {
        console.log('return from web api ', data);
        if (data.statusCode == 200) {
          this.serviceData.transaction.selectedRecipients = [];
          this.serviceData.transaction.selectedRecipients.push(data.body);
          observer.next(data.body);
        }
      }, error => observer.error(error))
    })
  }

  /**
   * gets Countries in geolocation  file save in assets/location.json
   */
  public getCountries() {
    return new Observable(observer => {
      this.webApi.getCountries().subscribe((data: any) => {
        if (data.Countries == null || data.Countries == undefined) {
          observer.error("Null or undefined Countries. Please check location.json")
        }
        if (data.Countries.Length == 0) {
          observer.error("No countries specified. Please check location.json ")
        }
        this.serviceData.Countries = data.Countries
        observer.next(data.Countries);
      }, error => {
        console.error(error);
        observer.error(error);
      })
    })
  }

  /**
   * set Selected Country in service data.
   */
  public setSelectedCountry(country: any) {
    if (country == null || country == undefined) {
      throw "You pass a country to be null or undefined country";
    }
    this.data.SelectedCountry = country;
  }

  public compareRecipients(param) {

  }

  // public getUserDetails() {
  //   return new Observable((observe) => {
  //     this.user.loadUser().subscribe((user: any) => {
  //       this.data.user = user;
  //       observe.next(user)
  //     }, error => {
  //       console.log("error on getting user", error)
  //       observe.error(error)
  //     }
  //     )
  //   })
  // }

  public getQA(alertId) {
    return new Observable(observer => {
      const sjon = require('secure-json-parse')
      this.webApi.getQADetails(alertId).subscribe((data: any) => {
        console.log("data from api", data, data.body[0].Id)
        let qaData = sjon.parse(sjon.parse(data.body[0].Details))
        console.log("data from qaData", qaData)
        if (qaData == null || qaData == undefined) {
          observer.error("Error on fetching data from dynamo")
        }
        if (qaData == 0) {
          observer.error("No data fetch from dynamodb with specified Id of " + alertId)
        }
        this.serviceData.transaction.emerging = qaData
        this.serviceData.transaction.emerging.QID = data.body[0].Id
        //this.data.transaction.emerging = qaData
        observer.next(qaData)
      }, error => {
        console.error(error);
        observer.error(error);
      })
    })
  }

  public checkQID(alertId) {
    return new Observable((observable) => {
      this.webApi.getQADetails(alertId).subscribe((data: any) => {
        console.log("check QID", data)
        if (data.body.length != 0) {

          console.log("has QID", data.body[0].Id)
          observable.next(data.body[0].Id)
        }
        else {
          observable.next(undefined)

        }
      }, error => {
        console.error(error);
        observable.error(error);
      })
    })
  }

  public getAnsEids(AlertId, QID, AID) {
    return new Observable((observable) => {
      console.log("AlertId", AlertId)
      if (QID) {
        this.webApi.getReportDetails(AlertId).subscribe((retrunData: any) => {
          console.log("data from report", retrunData)
          if (retrunData == null || retrunData == undefined) {
            observable.error("Error on fetching data from dynamodb")
          }
          if (retrunData == 0) {
            observable.error("No data fetch from dynamodb with specified Id of " + AlertId)
          }
          var rawData = retrunData['body']
          let eids = formatData(rawData, QID, AID)
          observable.next(eids)
          //observable.next(rawData)
          // console.log("this rawData", rawData)
          // let questionRow = retrunData['body'].find(x => x.qid = QID)
          // console.log("questionRow", questionRow)
          // let answerRows = questionRow.responses.find(x => x.aid = 'Answer' + AID)
          // console.log("answerRow", answerRows)
          // let eids = answerRows.eids
          // observable.next(eids)
          // console.log("getting EIDS", retrunData['body'], QID, AID)
          // let questionRow = retrunData['body'].find(x => x.qid = QID)
          // console.log("questionRow", questionRow)
          // let answerRows = questionRow.responses.find(x => x.aid = 'Answer' + AID)
          // console.log("answerRow", answerRows)
          // let eids = answerRows.eids
          // observable.next(eids)

          // formatData(retrunData['body'], QID, AID).then(eids => {
          //   observable.next(eids)
          // })

          // let eids = formatData()

        }, error => {
          console.error(error);
          observable.error(error);
        })
      } else {
        observable.next({ 'eid' : '' })
      }

    })

    function formatData(data, qid, aid) {

      console.log("getting EIDS", data, qid, aid)
      let questionRow = data.find(x => x.qid == qid)
      console.log("questionRow", questionRow)
      let answerRows = questionRow.responses.find(x => x.aid == 'Answer' + aid)
      console.log("answerRow", answerRows)
      let eids = answerRows.eids
      return eids


    }
  }



  public getReport(AlertId) {
    return new Observable((observe) => {
      this.webApi.getReportDetails(AlertId).subscribe((data: any) => {
        console.log("data from report", data)
        if (data == null || data == undefined) {
          observe.error("Error on fetching data from dynamodb")
        }
        if (data == 0) {
          observe.error("No data fetch from dynamodb with specified Id of " + AlertId)
        }

        this.serviceData.Report = formatData(this.serviceData.transaction, data['body'])
        //in case lang mahuli tawagin ang getReport
        setTimeout(() => {
          if (this.serviceData.transaction.deliveryDetail)
          this.serviceData.transaction.deliveryDetail.totalRead = this.serviceData.Report.report[0].dataRead
        }, 1000)
        observe.next(data['body'])
      }, error => {
        console.error(error);
        observe.error(error);
      })
    })

    function formatData(transaction, report) {
      report.forEach(element => {
        console.log("transaction recipient count", transaction.recipientCount)
        let tUnread
        if (element.no == 1 || element.isNotFU) {
          tUnread = parseInt(transaction.recipientCount) - parseInt(element.dataRead)
        } else {
          // tUnread = parseInt(element.totalnewrecipients) - parseInt(element.dataRead)
          // tUnread = element.eids.length - parseInt(element.dataRead)
          tUnread = parseInt(element.totalNewEids.length) - parseInt(element.dataRead)
        }
        element['dataUnread'] = tUnread
      });
      report = report.sort((a, b) => a.no - b.no)
      console.log("sorted report", report)
      return { delivery: [], report: report }
    }
  }


  public downloadExcel(data, filename) {
    return new Observable((observable) => {
      let excelData = []

      // for(let eid in data){
      //   const eacheid = {eid:eid}
      //   excelData.push(eacheid)
      // }
      console.log(filename);
      if(filename === "ASA-Responses EIDs-Response"){
        excelData = data;
      }
      else if(filename === "ASA-DeliveryDetails-TotalReads"){
        excelData = data;
      }else{
        data.forEach(element => {
          const eacheid = { 'Enterprise Id': element }
          excelData.push(eacheid)
        });
      }
      console.log("excelData", excelData)
      this.excel.exportAsExcelFile(excelData, filename, (error, success) => {
        if (error) {
          observable.error(error)
        }
        observable.next(success)
      })
    })
  }

  public downloadExcelResponses(data, filename) {
    return new Observable((observable) => {
      let excelData = []

      for(let eids in data){
        //const eacheid = {eids:eids}
        // excelData.push(eids)
      }

      console.log(filename);
      if(filename === "ASA-Responses EIDs-Response"){
        data;
      }
      else if(filename === "ASA-DeliveryDetails-TotalReads"){
        data;
      }else{
        data.forEach(element => {
          const eids = { 'Enterprise Id': element }
          data
        });
      }
      console.log("excelData", data)
      this.excel.exportAsExcelFile(data, filename, (error, success) => {
        if (error) {
          observable.error(error)
        }
        observable.next(success)
      })
    })
  }

  public deleteTransaction(deleteArr) {
    return new Observable((observable) => {

      deleteArr.forEach(element => {
        this.webApi.deleteTransaction(element.alertID).subscribe(result => {
          console.log("Result: ", result);
          return observable.next(result.statusCode)
        })
      });
    })
  }

  public updateQADetails(Id, param) {
    return new Observable((observable) => {
      this.webApi.updateQADetails(Id, param).subscribe(result => {
        return observable.next(result.statusCode)
      })
    })
  }

  public checkStatus() {
    return new Observable<boolean>((observable) => {
      this.webApi.checkBatchStatus().subscribe(result => {
        console.log("Transaction running?",result);
        if(result.statusCode === 200 && result.body.executions.length > 0){
          //There is a running transaction
          return observable.next(true)
        }else{
          return observable.next(false)
        }
        //return observable.next(result);
      })
    })
  }

  public sortModifiedDate(a, b) {

    return +(new Date(b.modifiedDate)) - +(new Date(a.modifiedDate));
  }


  public sortPublishedDate(a, b) {

    return +(new Date(b.publishedDate)) - +(new Date(a.publishedDate));
  }

  public sendReminder(param) {
    return new Observable((observer) => {
      this.webApi.sendReminder(param).subscribe(data => {
        console.log('return from web api ', data);
        if (data.statusCode == 201) {
          observer.next(data.body);
        }
      }, error => observer.error(error))
    })
  }

  public getNotifications(eid){
    return new Observable(observer => {
      this.webApi.getNotification(eid).subscribe((data:any) => {
        this.serviceData.etNotifs = [];
        let ETnotifs = [];
        ETnotifs = data.body.filter(item => (item.ragStatus == "Emerging Threat" && item.alertStatus == "Published"))

        this.serviceData.etNotifs = ETnotifs;
        observer.next(ETnotifs);
      }, error => observer.error(error))
    })
  }

  public getAlertCDPDetail(alertId, eid) {
    return new Observable((observer) => {
      this.webApi.getAlertResponseCDPDetails(alertId,eid).subscribe((result) => {
      if (result.statusCode == 200) {
        this.serviceData.CDPDetails = this.serviceData.CDPDetails == undefined ? {} : this.serviceData.CDPDetails;
        this.serviceData.CDPDetails = result.body;
        observer.next(result.body)
      } else {
        observer.error(result)
      }
      }, error => {
        observer.error(error)
      })
    })
  }

  /**
  * gets question and answer from dynamoDb using api
  */
   public getQADetails(alertId) {
    return new Observable(observer => {
      this.webApi.getAlertResponse(alertId).subscribe((data: any) => {
        console.log("data from api", data)
        var sjon = require('secure-json-parse')
        let qaData = sjon.parse(sjon.parse(data.body[0].Details))
        console.log("data from qaData", qaData)
        if (qaData == null || qaData == undefined) {
          observer.error("Error on fetching data from dynamo")
        }
        if (qaData == 0) {
          observer.error("No data fetch from dynamodb with specified Id of " + alertId)
        }
        this.serviceData.QA = qaData
        this.serviceData.QA.QID = data.body[0].Id
        this.data.QA = qaData
        let retQA = data.body.sort((a, b) => a.No - b.No)
        observer.next(retQA)
      }, error => {
        console.error(error);
        observer.error(error);
      })
    })
  }

  public setUserAccess(eid){
    console.log("after login eid: " + eid)
    this.serviceData.isCDP = false
    this.serviceData.userAccess = {
      "isAISL": false,
      "isAMD": false,
      "isAISLDelegate": false,
      "isVM": false,
      "isAssetControl": false,
      "canDownload": false
    }
    return new Observable((observe) => {
      this.webApi.getAccessMapping(eid).subscribe((result) => {
        console.log("access maping result: ", result)
        if (result.statusCode == 200) {
          this.serviceData.userAccess = result.body;
          this.serviceData.isCDP = this.serviceData.userAccess.isAISL || this.serviceData.userAccess.isAMD || this.serviceData.userAccess.isAISLDelegate ? true : false;
          observe.next(result)
          console.log("access maping result: ", result)
        }
      }, error => {
        console.log("access maping result: ", error)
        observe.error(error)
      })
    })
  }

  public getAnswer(QID) {
    return new Observable((observable) => {
      this.webApi.getAnswer(QID).subscribe(result => {
        observable.next(result)
      }, error => { observable.error(error) })
    })
  }

  public setReportsAccess(eid){
    console.log("after login eid: " + eid)
    this.serviceData.reportsAccess = {
      "UserDetail": false,
      "UsageMetrics": false,
      "TotalRewards": false,
      "RewardedPoints": false,
      "ISMSUsers": false,
      "CyberSecurityDetails": false,
      "ISMSActions": false,
    }
    return new Observable((observe) => {
      this.webApi.getAccessReports(eid).subscribe((result) => {
        console.log("access reports result: ", result)
        if (result.statusCode == 200) {
          this.serviceData.reportsAccess = result.body;
          observe.next(result)
          console.log("access reports result: ", result)
        }
      }, error => {
        console.log("access reports result: ", error)
        observe.error(error)
      })
    })
  }

  /*
  * updates notificaiotn alert.
  */
  public markQA(Id, isRead) {
    return new Observable(observer => {
      let param = {
        eid: this.serviceData.user.eid,
        Id: Id.split('|')[0]
      }

      let body = {
        "Id": Id + "|" + param.eid,
        "Read": isRead,
        "QID": Id
      }

      this.webApi.updateQAStatus(param, body).subscribe((data: any) => {
        //this.serviceData.selectedAlert.isRead = true;
        observer.next(data);
      }, error => {
        console.error(error);
        observer.error(error);
      })

    });
  }

  public getAllAnswered(QID) {
    return new Observable((observable) => {
      this.webApi.getAllAnswered(QID).subscribe(result => {
        observable.next(result)
      }, error => { observable.error(error) })
    })
  }

    /*
  * updates ET notificaiotn alert.
  */
  public ETmarkAlert(alertID, type, isRead) {
    return new Observable(observer => {
      let param = {
        eid: this.serviceData.user.eid,
        alertID: alertID,
        type: type
      }

      let body = {
        "userNotifId": param.eid + "|" + param.alertID + "|" + param.type,
        "userNotifIds": [],
        "updateObject": { "isRead": isRead }
      }
      console.log("body", body)
      this.webApi.updateNotification(param, body).subscribe((data: any) => {
        // this.serviceData.selectedAlert.isRead = true;
        observer.next(data);
      }, error => {
        console.error(error);
        observer.error(error);
      })

    });
  }

  public submitAnswer(data) {
    return new Observable((observe) => {
      this.webApi.postAnswer(data).subscribe((result) => {
        observe.next(result)
      }, error => {
        observe.error(error)
      })
    })
  }

  public getReportsPage():Observable<any>{
    return new Observable(observer => {
      this.webApi.getReportsPage().subscribe(reports => {
        observer.next(reports)
      },error => {
        observer.error(error)
      })
    })
  }

  public generateReport(table, LastEvaluatedKey,  row, col) {
    return new Observable((observe) => {

      console.log("scanning...")
        let self = this.webApi
        let serviceData = this.serviceData
        oscan()
        function oscan(){
        self.generateReport(table, LastEvaluatedKey, row, col).subscribe((result) => {
          serviceData.extract.rows[row][col].data.push(...result.data)
          LastEvaluatedKey = result.LastEvaluatedKey
          if (result.status != "end"){
            console.log(result.data.length, " results found, scanning more...")
            oscan()
          } else {
            let value = serviceData.extract.rows[row][col].data;
            let header = serviceData.extract.rows[row][col].body.ColumnHeaders;

            let concatenatedString = header + "\r\n";

            for(let i = 0; i < value.length; i++){
              let object = value[i]
              let headerArr = header.split(',');
              for (let val = 0; val < headerArr.length; val++){
                if(object[headerArr[val]] != undefined){
                  let tempt = JSON.stringify( object[headerArr[val]]).replace(",", " ")
                  if(headerArr.length - 1 == val) concatenatedString = concatenatedString + tempt + "\r\n"
                  else concatenatedString = concatenatedString + tempt + ","
                } else {
                  if(headerArr.length - 1 == val) concatenatedString = concatenatedString + ' ' + "\r\n"
                  else concatenatedString = concatenatedString + ' ' + ","
                }
              }

            }
            serviceData.extract.rows[row][col].concatenatedString = concatenatedString;
            serviceData.extract.rows[row][col].loading = false;
            serviceData.extract.rows[row][col].button = "Download"
          }



        }, error => {
          observe.error(error)
        })
      }

    })
  }

  public getCDPOperationDataTest(alertID, eid){
    return new Observable((observer) => {
      this.webApi.getCDPOperationDataTest(alertID,eid).subscribe((result) => {
        if (result.statusCode == 200) {
          this.serviceData.clientAccounts = this.serviceData.clientAccounts == undefined ? {} : this.serviceData.clientAccounts;

          this.serviceData.clientAccounts.detail = result.body;

          this.serviceData.clientAccounts.detail.WeightedOperationDataValues = this.serviceData.clientAccounts.detail.WeightedOperationDataValues.sort(this.sortNameLength);

          observer.next(result)

        } else {
          observer.error(result)
        }
      }, error => {
        observer.error(error)
      })
    })
  }

  public sortNameLength(a, b) {
    let retVal = 0;

    if (a.ClientName.length > b.ClientName.length) {
      retVal = 1;
    } else {
      retVal = -1
    }

    return retVal;
  }

  public updateETstatus(status, alertID){
    return new Observable(observer => {
      let param = {
        eid: this.serviceData.user.eid,
        alertID: alertID,
        type: "N"
      }

      let body = {
        "userNotifId": param.alertID,
        "userNotifIds": [],
        "updateObject": { "etStatus": status }
      }
      this.webApi.updateETstatus(param, body).subscribe(ret => {
        observer.next(ret);
      },error => {
        observer.error(error)
      })
    })
  }

  public getTotalReads(alertID){
    return new Observable(observer => {
      this.webApi.getTotalReads(alertID).subscribe(data => {
        let eids = [];

        if(data.statusCode == 200){
          data.body.forEach(user => {
            let eid = user.userNotificationId.split('|')[0];
            eids.push(eid);
          });

          observer.next(eids);
        }

      })
    })
  }

  public getTotalPending(alertID){
    return new Observable(observer => {
      this.webApi.getTotalPending(alertID).subscribe(data => {
        let eids = [];
        if(data.statusCode == 200){
          data.body.forEach(user => {
            let eid = user.userNotificationId.split('|')[0];
            eids.push(eid);
          });

          observer.next(eids);
        }

      })
    })
  }

    /**
   * checkRespond status
   */
    public checkEtStatus(alrtIDs, eid) {
      return new Observable(observer => {
        let body = {
          "etstatus": true,
          "alertIDs": alrtIDs,
          "eid":eid,
        }
        this.webApi.postETstatus(body).subscribe(data => {
          console.log('return from web api checkEtStatus', data);
          if (data.statusCode == 201 || data.statusCode == 200) {
            observer.next(data.body)
          }
        }, error => {
          observer.error(error)
        })
      });
    }

  // public getTotalPendingSMS(alertID){
  //   return new Observable(observer => {
  //     this.webApi.getTotalPendingSMS(alertID).subscribe(data => {
  //       let eids = [];
  //       if(data.statusCode == 200){
  //         data.body.forEach(user => {
  //           let eid = user.eid.split('|')[0];
  //           eids.push(eid);
  //         });

  //         observer.next(eids);
  //       }

  //     })
  //   })
  // }

  // public checkValidPhone(phone){
  //   return this.phone.checkPhone(phone)
  // }

  /**
   * check if user have phone numbers
   */
    //  public checkUserPhoneNumbers(param) {
    //   return new Observable(observer => {
    //     console.log("params for checking: ", param)
    //     if(param.recipient != ""){
    //       this.webApi.postPhoneNumbers(param).subscribe((data: [this.selectedAnswer.newComsIndex].qaItems[this.selectedAnswer.qaItemsIndex]) => {
    //         console.log("retrieved data: ", data)
    //         let statuscode = data.statusCode == undefined ? data["body-json"].statusCode : data.statusCode;
    //         let tempArray = []
    //         let validArray = []
    //         let validString = ''
    //         if (statuscode == 201) {
    //           // this.serviceData.temporary = this.serviceData.temporary ? this.serviceData.temporary : {};

    //           // this.serviceData.temporary = data.body == undefined ? data["body-json"].body : data.body;

    //           if( data.body.valid.length > 0){
    //             if(data.body.type == "eids"){
    //               data.body.valid.forEach(element => {
    //                 console.log("loop element", element)
    //                 validArray.push(element.eid + "@accenture.com")
    //               });
    //               validString = validArray.join(";")
    //             } else {
    //               data.body.valid.forEach(element => {
    //                 validArray.push(element.primaryNumber)
    //               });
    //               validString = validArray.join(";")
    //             }
    //               // if(param.type == "eids")
    //               //   this.serviceData.transaction.recipients.eid = validString
    //               // else
    //               //   this.serviceData.transaction.recipients.numbers = validString
    //             //   console.log("validString", validString)
    //             // observer.next({status: true, results: tempArray, validString: validString});
    //           }

    //           if( data.body.invalid.length > 0){
    //             if(data.body.type == "eids"){
    //               data.body.invalid.forEach(element => {
    //                 tempArray.push(element + "@accenture.com")
    //               });
    //               this.serviceData.temporary = tempArray
    //             } else {
    //               this.serviceData.temporary = data.body.invalid
    //               tempArray = data.body.invalid
    //             }
    //             statuscode = null
    //             observer.next({status: true, results: tempArray, validString: validString});
    //           } else {
    //             this.serviceData.temporary = []
    //             observer.next({status: false, results: tempArray, validString: validString});
    //           }
    //         }
    //       }, error => observer.error(error))
    //     } else {
    //       this.serviceData.temporary = []
    //       observer.next({status: false, results: '', validString: ''});
    //     }
    //   })
    // }

}
